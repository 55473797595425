import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../store/AppContext.js";
/* import { SaldoTotal } from "../../Helper/SaldoTotal"; */
import "../../../../../styles/TablaClientes.css";

//Aquí se genera el Listado de Clientes que se muestra en Contabilidad. Desde DireconTributaria.js se ejecuta el action que llama la info de la bd. Esta lista se guarda en store.clientesDt.
// Respecto al CSS del Paginator, se debió crear un .css adicional solo para setear los colores.

const ListaClientesConta = ({
	setClienteSeleccionado,
	setClienteContaCliqueado,
}) => {
	const { store } = useContext(Context);

	const HandlerClick = (object, event) => {
		if (event.target.className != "telefono") {
			setClienteContaCliqueado(object);
			setClienteSeleccionado(true);
		}
	};

	//Funcion para el Saldo
	const CalculoSaldo = (objeto, meses) => {
		let montoPagado = 0;
		let montoCobrado = 0;
		if (objeto.pagosContabilidadID != undefined) {
			for (let index = 0; index < objeto.pagosContabilidadID.length; index++) {
				montoPagado =
					montoPagado + parseInt(objeto.pagosContabilidadID[index].montoPagado);
				montoCobrado =
					montoCobrado +
					parseInt(objeto.pagosContabilidadID[index].montoCobrado);
			}
		}
		return montoCobrado - montoPagado;
	};

	//La siguiente funcion despliega la lista de Clientes con sus respectivas Columnas.
	const ListaDesplegarClientes = (objeto, i) => {
		return (
			<tr
				className='trVistaAmpliada'
				key={i}
				onClick={(e) => HandlerClick(objeto, e)}
			>
				<td>{objeto.id}</td>
				<td>{objeto.razon}</td>
				<td>{objeto.rut}</td>
				<td>
					{objeto.correo}
					{objeto.correoSecundario === "" ? null : (
						<span>, {objeto.correoSecundario}</span>
					)}
					{objeto.correoTerciario === "" ? null : (
						<span>, {objeto.correoTerciario}</span>
					)}
				</td>
				<td className='telefono' style={{ cursor: "copy" }}>
					{objeto.fono}
				</td>
				<td>{objeto.vigente}</td>
				<td>
					$
					{objeto.pagosContabilidadID != undefined
						? objeto.vigente == "No"
							? "0"
							: CalculoSaldo(objeto, false)
						: null}
				</td>
				<td>
					{objeto.dicom == "Si"
						? objeto.repetido == "Si"
							? "Dicom"
							: "Riesgo"
						: objeto.repetido == "Si"
						? "Dicom"
						: "Normal"}
				</td>
			</tr>
		);
	};

	return (
		<div className={store.witch ? "" : "grid-x grid-margin-x"}>
			{store.clientesContabilidad != null ? (
				<table
					className={`table hover ${store.witch ? "tablaClientes" : ""}`}
					id='tblData'
				>
					<thead>
						<tr>
							<th className='id' scope='col'>
								Id
							</th>
							<th scope='col'>Razon Social</th>
							<th className='rut' scope='col'>
								Rut
							</th>
							<th scope='col'>Correo</th>
							<th className='telefono' scope='col'>
								Teléfono
							</th>
							<th scope='col'>Vigente</th>
							<th className='saldo' scope='col'>
								Saldo
							</th>
							<th className='situacion' scope='col'>
								Situacion
							</th>
						</tr>
					</thead>
					<tbody>
						{store.clientesContabilidad != null ? (
							store.clientesContabilidad.map((objeto, i) =>
								ListaDesplegarClientes(objeto, i)
							)
						) : (
							<td colSpan='9' style={{ height: "100px", padding: "20px" }}>
								<h2 className='text-center'> - no hay datos -</h2>
							</td>
						)}
					</tbody>
				</table>
			) : null}
		</div>
	);
};

export default ListaClientesConta;
