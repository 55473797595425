import React, { useState, useContext, Fragment } from "react";
import { Context } from "../../../store/AppContext";
import BuscadorNormal from "./BuscadorNormal";
import ResultadoBusqueda from "./ResultadoBusqueda";
import Head from "../../Head";

//Esta es la Vista de los No Administradores ni Cobranzas.

const VistaContaUsuarioNormal = () => {
	const { actions } = useContext(Context);
	const [buscando, setBuscando] = useState(false); //Detecta si hemos apretado el botón Buscar o hemos dato Enter
	const [clienteContaBuscado, setClienteContaBuscado] = useState(null);
	const titulosHead = [
		"Bienvenido al Servicio de Contabilidad",
		"Consulta información respecto a Clientes con este servicio contratado.",
	];

	const HandlerBuscar = (event) => {
		actions.getBusquedaContabilidad(clienteContaBuscado);
		buscando ? setBuscando(true) : setBuscando(true);
	};

	return (
		<Fragment>
			<Head contenido={titulosHead} />
			<div className='row'>
				<div className='column'>
					<div>
						<h5>Consulta si un cliente tiene el servicio contratado</h5>
						<p>Puedes hacer busqueda según los siguientes criterios</p>
						<ul>
							<li>Razon Social o Nombre de Representante</li>
							<li>Rut de la sociedad o Rut Representante. ex: 76717904-9</li>
							<li>Correo registrado</li>
							<li>Fono registrado</li>
						</ul>
					</div>
					<div className='grid-x grid-margin-x'>
						<BuscadorNormal
							setClienteContaBuscado={setClienteContaBuscado}
							buscando={buscando}
							setBuscando={setBuscando}
						/>
						<button
							className='cell small-2 submit success button'
							onClick={(e) => HandlerBuscar(e)}
						>
							Buscar
						</button>
					</div>
					{clienteContaBuscado != null && buscando ? (
						<ResultadoBusqueda clienteContaBuscado={clienteContaBuscado} />
					) : null}
				</div>
			</div>
		</Fragment>
	);
};

export default VistaContaUsuarioNormal;
