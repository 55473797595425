import React from 'react';

const RenderPatenteTabla = ({ patentes, onClickFila }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>RUT</th>
          <th>Razón Social</th>
          <th>Correo</th>
          <th>Estado</th>
          <th>Código</th>
          <th>N° Expediente</th>
          <th>Comentarios</th>
        </tr>
      </thead>
      <tbody>
        {patentes.map(patente => (
          <tr 
            key={patente.id} 
            onClick={() => onClickFila && onClickFila(patente)} 
            style={{cursor: onClickFila ? 'pointer' : 'default'}}
          >
            <td>{patente.rut}</td>
            <td>{patente.razonSocial}</td>
            <td>{patente.correo}</td>
            <td>{patente.estado}</td>
            <td>{patente.codigo && patente.codigo.length > 0 ? patente.codigo[patente.codigo.length - 1][0] : 'N/A'}</td>
            <td>{patente.numeroExpediente && patente.numeroExpediente.length > 0 ? patente.numeroExpediente[patente.numeroExpediente.length - 1][0] : 'N/A'}</td>
            <td>{patente.terminoComentarios || 'N/A'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RenderPatenteTabla;
