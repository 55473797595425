import React, { useState, useContext, useEffect } from 'react';
import { formatRut, validateRut } from '../../Helper/RutUtils';
import { Context } from "../../store/AppContext";

const EditarPatente = ({ cambios, setCambios, formErrors }) => {
  const { store } = useContext(Context);
  const { patente } = store;
  const [rutError, setRutError] = useState('');

  useEffect(() => {
    if (patente && patente.terminoComentarios && !cambios.terminoComentarios) {
      setCambios(prev => ({
        ...prev,
        terminoComentarios: patente.terminoComentarios
      }));
    }
  }, [patente]);

  if (!patente) return null;

  const estadosOptions = [
    "En Proceso",
    "Terminada",
    "Rechazada",
    "Pendiente",
    "Devolucion",
    "Cliente No Responde",
    "No Sigue Proceso",
    "Ultima Etapa",
    "En Revision"
  ];

  const handleInputChange = (key, value) => {
    if (key === 'rut') {
      const formattedRut = formatRut(value);
      setCambios(prev => ({ ...prev, [key]: formattedRut }));
      
      if (formattedRut && !validateRut(formattedRut)) {
        setRutError('Por favor, ingrese un RUT válido con el formato xx.xxx.xxx-x');
      } else {
        setRutError('');
      }
    } else {
      setCambios(prev => ({ ...prev, [key]: value }));
    }
  };

  return (
    <div className="grid-x grid-padding-x">
      <div className="cell medium-6" style={{borderRight: '1px solid #ccc'}}>
        <div style={{marginBottom: '1rem', textAlign: 'center'}}><h4>Datos de la Patente (Actuales)</h4></div>
        
        <div style={{marginBottom: '1rem'}}>
          <strong>Código:</strong>
          <input
            type="text"
            value={patente.codigo || ''}
            disabled
            style={{width: '100%', marginTop: '0.5rem'}}
          />
        </div>

        <div style={{marginBottom: '1rem'}}>
          <strong>Número de Expediente:</strong>
          <input
            type="text"
            value={patente.numeroExpediente || ''}
            disabled
            style={{width: '100%', marginTop: '0.5rem'}}
          />
        </div>

        <div style={{marginBottom: '1rem'}}>
          <strong>Rut:</strong>
          <input
            type="text"
            value={patente.rut || ''}
            disabled
            style={{width: '100%', marginTop: '0.5rem'}}
          />
        </div>

        <div style={{marginBottom: '1rem'}}>
          <strong>Razón Social:</strong>
          <input
            type="text"
            value={patente.razonSocial || ''}
            disabled
            style={{width: '100%', marginTop: '0.5rem'}}
          />
        </div>

        <div style={{marginBottom: '1rem'}}>
          <strong>Correo:</strong>
          <input
            type="text"
            value={patente.correo || ''}
            disabled
            style={{width: '100%', marginTop: '0.5rem'}}
          />
        </div>

        <div style={{marginBottom: '1rem'}}>
          <strong>Estado:</strong>
          <select
            value={patente.estado || ''}
            disabled
            style={{width: '100%', marginTop: '0.5rem'}}
          >
            <option value="">Seleccione un estado</option>
            {estadosOptions.map((estado) => (
              <option key={estado} value={estado}>{estado}</option>
            ))}
          </select>
        </div>

        <div style={{marginBottom: '1rem'}}>
          <strong>Revisión de Estado:</strong>
          <input
            type="date"
            value={patente.revisionEstado || ''}
            disabled
            style={{width: '100%', marginTop: '0.5rem'}}
          />
        </div>

        <div style={{marginBottom: '1rem'}}>
          <strong>Término Comentarios:</strong>
          <textarea
            value={patente.terminoComentarios || ''}
            disabled
            style={{width: '100%', marginTop: '0.5rem', minHeight: '6rem'}}
          />
        </div>
      </div>

      <div className="cell medium-6">
        <div style={{marginBottom: '1rem', textAlign: 'center'}}><h4>Cambios a Realizar</h4></div>
        
        <div style={{marginBottom: '1rem'}}>
          <strong>Código:</strong>
          <input
            type="text"
            value={cambios.codigo || ''}
            onChange={(e) => handleInputChange('codigo', e.target.value)}
            placeholder="Ingrese el código"
            style={{width: '100%', marginTop: '0.5rem'}}
          />
          {formErrors.codigo && <small style={{color: 'red', display: 'block'}}>{formErrors.codigo}</small>}
        </div>

        <div style={{marginBottom: '1rem'}}>
          <strong>Número de Expediente:</strong>
          <input
            type="text"
            value={cambios.numeroExpediente || ''}
            onChange={(e) => handleInputChange('numeroExpediente', e.target.value)}
            placeholder="Ingrese el número de expediente"
            style={{width: '100%', marginTop: '0.5rem'}}
          />
          {formErrors.numeroExpediente && <small style={{color: 'red', display: 'block'}}>{formErrors.numeroExpediente}</small>}
        </div>

        <div style={{marginBottom: '1rem'}}>
          <strong>Rut:</strong>
          <input
            type="text"
            value={cambios.rut || ''}
            onChange={(e) => handleInputChange('rut', e.target.value)}
            maxLength={12}
            placeholder="Ej: 17.011.079-K"
            style={{width: '100%', marginTop: '0.5rem'}}
          />
          {rutError && <small style={{color: 'red', display: 'block'}}>{rutError}</small>}
        </div>

        <div style={{marginBottom: '1rem'}}>
          <strong>Razón Social:</strong>
          <input
            type="text"
            value={cambios.razonSocial || ''}
            onChange={(e) => handleInputChange('razonSocial', e.target.value)}
            placeholder="Ingrese la razón social"
            style={{width: '100%', marginTop: '0.5rem'}}
          />
        </div>

        <div style={{marginBottom: '1rem'}}>
          <strong>Correo:</strong>
          <input
            type="text"
            value={cambios.correo || ''}
            onChange={(e) => handleInputChange('correo', e.target.value)}
            placeholder="Ingrese el correo"
            style={{width: '100%', marginTop: '0.5rem'}}
          />
          {formErrors.correo && <small style={{color: 'red', display: 'block'}}>{formErrors.correo}</small>}
        </div>

        <div style={{marginBottom: '1rem'}}>
          <strong>Estado:</strong>
          <select
            value={cambios.estado || ''}
            onChange={(e) => handleInputChange('estado', e.target.value)}
            style={{width: '100%', marginTop: '0.5rem'}}
          >
            <option value="">Seleccione un estado</option>
            {estadosOptions.map((estado) => (
              <option key={estado} value={estado}>{estado}</option>
            ))}
          </select>
        </div>

        <div style={{marginBottom: '1rem'}}>
          <strong>Revisión de Estado:</strong>
          <input
            type="date"
            value={cambios.revisionEstado || ''}
            onChange={(e) => handleInputChange('revisionEstado', e.target.value)}
            style={{width: '100%', marginTop: '0.5rem'}}
          />
        </div>

        <div style={{marginBottom: '1rem'}}>
          <strong>Término Comentarios:</strong>
          <textarea
            value={cambios.terminoComentarios || ''}
            onChange={(e) => handleInputChange('terminoComentarios', e.target.value)}
            style={{width: '100%', marginTop: '0.5rem', minHeight: '6rem'}}
            placeholder={patente.terminoComentarios || ''}
          />
          {formErrors.terminoComentarios && 
            <small style={{color: 'red', display: 'block'}}>
              {formErrors.terminoComentarios}
            </small>
          }
        </div>
      </div>
    </div>
  );
};

export default EditarPatente; 