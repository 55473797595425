import React, { useState, useEffect, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Context } from "../../store/AppContext";
import ModalPatenteSeleccionada from './ModalPatenteSeleccionada';
import RenderPatenteTabla from './RenderPatenteTabla';

const PatentesEnTramite = ({ onRegresar }) => {
  const { store, actions } = useContext(Context);
  const [vistaKanban, setVistaKanban] = useState(() => {
    const savedView = localStorage.getItem('vistaKanban');
    return savedView !== null ? JSON.parse(savedView) : false;
  });
  const [modalPatente, setModalPatente] = useState(null);
  const onVista = "EnTramite";

  useEffect(() => {
    actions.getPatentes(onVista);
  }, []);

  useEffect(() => {
    localStorage.setItem('vistaKanban', JSON.stringify(vistaKanban));
  }, [vistaKanban]);
 
  const columnas = [
    { id: "Pendiente", estados: ["Pendiente"] },
    { id: "En Proceso", estados: ["En Proceso", "En Revision"] },
    { id: "Terminada", estados: ["Terminada", "Ultima Etapa"] },
    { id: "Rechazada", estados: ["Rechazada"] },
    { id: "Muertos", estados: ["Devolucion", "Cliente No Responde", "No Sigue Proceso"] }
  ];

  const toggleVista = () => setVistaKanban(!vistaKanban);

  const abrirModal = (patente) => {
    actions.getPatente(patente.id);
    setTimeout(() => {
      setModalPatente(store.patente);
    }, 700);
  };

  const actualizarModal = (patenteId) => {
    actions.getPatente(patenteId);
    setTimeout(() => {
      setModalPatente(store.patente);
    }, 700);
  };

  const cerrarModal = () => {
    setModalPatente(null);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const nuevasPatentes = [...store.patentes];
    const patenteIndex = nuevasPatentes.findIndex(p => p.id === parseInt(draggableId));
    
    // Obtener fecha actual en formato correcto
    const fechaActual = new Date().toLocaleString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).split('/').reverse().join('-');

    // Obtener información del usuario actual
    const usuarioEditor = `${store.usuarioActual.nombre} ${store.usuarioActual.apellido}`;

    // Actualizar el estado y la fechaEdicion
    nuevasPatentes[patenteIndex] = {
      ...nuevasPatentes[patenteIndex],
      estado: destination.droppableId,
      fechaEdicion: [
        ...(nuevasPatentes[patenteIndex].fechaEdicion || []),
        [fechaActual, usuarioEditor]
      ]
    };

    actions.editarPatente(
      nuevasPatentes[patenteIndex].id, 
      nuevasPatentes[patenteIndex], 
      onVista
    );
  };

  // Filtrar las patentes excluyendo las "Terminadas"
  const patentesFiltradas = store.patentes ? store.patentes.filter(patente => patente.estado !== "Terminada") : [];

  const renderTabla = () => (
    <RenderPatenteTabla 
      patentes={patentesFiltradas} 
      onClickFila={abrirModal}
    />
  );

  const renderKanban = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="grid-x grid-margin-x small-up-1 medium-up-5" style={{ margin: '0 -0.5rem' }}>
        {columnas.map(columna => (
          <Droppable droppableId={columna.id} key={columna.id}>
            {(provided) => (
              <div 
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="cell"
                style={{ padding: '0 0.5rem' }}
              >
                <h4>{columna.id}</h4>
                <div className="callout" style={{ padding: '0.5rem' }}>
                  {patentesFiltradas
                    .filter(patente => columna.estados.includes(patente.estado))
                    .map((patente, index) => (
                      <Draggable key={patente.id} draggableId={patente.id.toString()} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="card"
                            style={{
                              backgroundColor: 'yellow',
                              padding: '0.75rem',
                              marginBottom: '0.5rem',
                              textAlign: 'center',
                              cursor: 'pointer',
                              minHeight: '3rem',
                              width: '100%',
                              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                              ...provided.draggableProps.style
                            }}
                            onClick={() => abrirModal(patente)}
                          >
                            {patente.razonSocial}
                          </div>
                        )}
                      </Draggable>
                    ))
                  }
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </DragDropContext>
  );

  return (
    <div>
      <div className="grid-x grid-padding-x">
        <div className="cell">
          <div className="button-group">
            <button className="button" onClick={onRegresar}>Regresar</button>
            <button className="button success float-right" onClick={toggleVista}>
              {vistaKanban ? "Disposición Lista" : "Disposición Kanban"}
            </button>
          </div>
          <h2>Patentes En Trámite</h2>
          {vistaKanban ? renderKanban() : renderTabla()}
          <ModalPatenteSeleccionada 
            patente={modalPatente} 
            onClose={cerrarModal}
            onUpdate={actualizarModal}
            onVista={onVista}
          />
        </div>
      </div>
    </div>
  );
};

export default PatentesEnTramite;
