// Expresión regular para validar el formato RUT
export const rutRegex = /^\d{1,2}\.\d{3}\.\d{3}-[0-9kK]$/;

// Función para formatear el RUT
export const formatRut = (value) => {
  // Si el valor está vacío, retornamos string vacío
  if (!value) return '';
  
  const lastChar = value.slice(-1).toUpperCase();
  const isLastCharK = lastChar === 'K';
  // Limpiamos el valor manteniendo solo números y la K si existe
  const cleaned = value.replace(/[^\dkK]/g, '');
  
  let formatted = '';
  
  if (cleaned.length <= 2) {
    formatted = cleaned;
  } else if (cleaned.length <= 5) {
    formatted = `${cleaned.slice(0, 2)}.${cleaned.slice(2)}`;
  } else if (cleaned.length <= 8) {
    formatted = `${cleaned.slice(0, 2)}.${cleaned.slice(2, 5)}.${cleaned.slice(5)}`;
  } else {
    formatted = `${cleaned.slice(0, 2)}.${cleaned.slice(2, 5)}.${cleaned.slice(5, 8)}-${cleaned.slice(8)}`;
  }

  return formatted;
};

// Función para validar el RUT
export const validateRut = (value) => {
  return rutRegex.test(value);
};
