import React, { useState, useContext } from 'react';
import { Context } from "../../store/AppContext";
import EditarPatente from './EditarPatente';
import { validateRut } from '../../Helper/RutUtils';
import RenderPatenteDatos from './RenderPatenteDatos';

const ModalPatenteSeleccionada = ({ patente, onClose, onUpdate, onVista, busqueda=null }) => {
  const { store, actions } = useContext(Context);
  const [isEditing, setIsEditing] = useState(false);
  const [cambios, setCambios] = useState({});
  const [rutError, setRutError] = useState('');
  const [formErrors, setFormErrors] = useState({
    codigo: '',
    numeroExpediente: '',
    correo: '',
    terminoComentarios: ''
  });

  if (!patente) return null;

  // Verificar si el usuario tiene permisos de administrador
  const tipoUsuario = store.usuarioActual?.tipo;
  const tienePermisosAdmin = ['Administrador', 'Super Administrador'].includes(tipoUsuario);
  const estadosRestringidos = ["Terminada", "Ultima Etapa"];
  
  const tienePermisosEdicion = estadosRestringidos.includes(patente.estado)
    ? tienePermisosAdmin
    : onVista === "EnTramite" || onVista === "Busqueda"
      ? ['Administrador', 'Super Administrador', 'Soporte'].includes(tipoUsuario)
      : tienePermisosAdmin;

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      codigo: '',
      numeroExpediente: '',
      correo: '',
      terminoComentarios: ''
    };

    // Validar código
    if (cambios.codigo && !/^\d+$/.test(cambios.codigo)) {
      newErrors.codigo = 'El código debe contener solo números';
      isValid = false;
    }

    // Validar número de expediente
    if (cambios.numeroExpediente && !/^\d+$/.test(cambios.numeroExpediente)) {
      newErrors.numeroExpediente = 'El número de expediente debe contener solo números';
      isValid = false;
    }

    // Validar correo
    if (cambios.correo) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(cambios.correo)) {
        newErrors.correo = 'Por favor, ingrese un correo electrónico válido';
        isValid = false;
      }
    }

    // Validar término/comentarios
    if (cambios.terminoComentarios && cambios.terminoComentarios.length > 150) {
      newErrors.terminoComentarios = 'El comentario no puede exceder los 150 caracteres';
      isValid = false;
    }

    setFormErrors(newErrors);
    return isValid;
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      console.log(patente.id);      
    } else {
      setCambios({});
      setRutError(''); // Limpiar error al cancelar edición
    }
  };

  const handleCloseClick = () => {
    setIsEditing(false);
    setCambios({});
    setRutError('');
    onClose();
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();

    // Validar RUT si hay cambios en él
    if (cambios.rut) {
      if (!validateRut(cambios.rut)) {
        setRutError('Por favor, ingrese un RUT válido con el formato xx.xxx.xxx-x');
        return;
      }
    }

    if (!validateForm()) {
      return;
    }

    // Si hay error de RUT, no permitir guardar
    if (rutError) {
      return;
    }

    const fechaActual = new Date().toLocaleString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).split('/').reverse().join('-');

    const usuarioEditor = `${store.usuarioActual.nombre} ${store.usuarioActual.apellido}`;

    // Preparar los datos actualizados
    const datosActualizados = {
      ...patente,
      ...cambios
    };

    // Manejar código como array de tuplas
    if (cambios.codigo) {
      datosActualizados.codigo = [
        ...(patente.codigo || []),
        [parseInt(cambios.codigo), fechaActual]
      ];
    }

    // Manejar numeroExpediente como array de tuplas
    if (cambios.numeroExpediente) {
      datosActualizados.numeroExpediente = [
        ...(patente.numeroExpediente || []),
        [parseInt(cambios.numeroExpediente), fechaActual]
      ];
    }

    // Actualizar fechaEdicion
    datosActualizados.fechaEdicion = [
      ...(patente.fechaEdicion || []),
      [fechaActual, usuarioEditor]
    ];

    actions.editarPatente(patente.id, datosActualizados, onVista, busqueda);

    setCambios({});
    setIsEditing(false);
    setRutError('');
  };

  const handleRefresh = () => {
    if (patente && patente.id) {
      onUpdate(patente.id);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('¿Está seguro que desea eliminar esta patente? Esta acción no se puede deshacer.')) {
      await actions.borrarPatente(patente.id);
      onClose();
    }
  };

  return (
    <div className="reveal-overlay" style={{display: 'block'}}>
      <div className="reveal large" style={{display: 'block', width: '90%', maxWidth: '75rem'}}>
        <div className="grid-x grid-padding-x">
          <div className="cell">
            <div className="button-group" style={{justifyContent: 'space-between', marginBottom: '1rem'}}>
              <div>
                <button 
                  className="button success" 
                  onClick={handleEditClick} 
                  style={{
                    marginRight: '1rem',
                    opacity: tienePermisosEdicion ? '1' : '0.6'
                  }}
                  disabled={!tienePermisosEdicion}
                >
                  {isEditing ? 'Dejar de Editar' : 'Editar'}
                </button>
                <button 
                  className="button warning"
                  onClick={handleDelete}
                  disabled={!tienePermisosAdmin}
                  style={{
                    opacity: tienePermisosAdmin ? '1' : '0.6'
                  }}
                >
                  Eliminar
                </button>
              </div>
              <div>
                <button 
                  className={`button ${Object.keys(cambios).length > 0 ? 'primary' : 'secondary'}`}
                  disabled={Object.keys(cambios).length === 0 || rutError !== ''} 
                  style={{
                    marginRight: '1rem', 
                    opacity: Object.keys(cambios).length === 0 || rutError !== '' ? '0.6' : '1'
                  }}
                  onClick={handleSaveChanges}
                >
                  Guardar Cambios
                </button>
                <button 
                  className="button primary"
                  style={{
                    marginRight: '1rem',
                    padding: '0.5rem',
                    opacity: isEditing ? '0.6' : '1'
                  }}
                  disabled={isEditing}
                  onClick={handleRefresh}
                >
                  <svg className="svg-icon" viewBox="0 0 20 20" style={{width: '20px', height: '20px'}}>
                    <path fill="white" d="M3.254,6.572c0.008,0.072,0.048,0.123,0.082,0.187c0.036,0.07,0.06,0.137,0.12,0.187C3.47,6.957,3.47,6.978,3.484,6.988c0.048,0.034,0.108,0.018,0.162,0.035c0.057,0.019,0.1,0.066,0.164,0.066c0.004,0,0.01,0,0.015,0l2.934-0.074c0.317-0.007,0.568-0.271,0.56-0.589C7.311,6.113,7.055,5.865,6.744,5.865c-0.005,0-0.01,0-0.015,0L5.074,5.907c2.146-2.118,5.604-2.634,7.971-1.007c2.775,1.912,3.48,5.726,1.57,8.501c-1.912,2.781-5.729,3.486-8.507,1.572c-0.259-0.18-0.618-0.119-0.799,0.146c-0.18,0.262-0.114,0.621,0.148,0.801c1.254,0.863,2.687,1.279,4.106,1.279c2.313,0,4.591-1.1,6.001-3.146c2.268-3.297,1.432-7.829-1.867-10.101c-2.781-1.913-6.816-1.36-9.351,1.058L4.309,3.567C4.303,3.252,4.036,3.069,3.72,3.007C3.402,3.015,3.151,3.279,3.16,3.597l0.075,2.932C3.234,6.547,3.251,6.556,3.254,6.572z"></path>
                  </svg>
                </button>
                <button className="button alert" onClick={handleCloseClick}>Cerrar</button>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-x grid-padding-x">
          <div className="cell">
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <h3>Detalles de la Patente</h3>
              {Object.values(formErrors).some(error => error !== '') && (
                <span style={{ color: 'red', fontSize: '1rem' }}>
                  Por favor, corrija los errores en el formulario
                </span>
              )}
            </div>
            {isEditing ? (
              <EditarPatente
                cambios={cambios}
                setCambios={setCambios}
                formErrors={formErrors}
              />
            ) : (
              <RenderPatenteDatos/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPatenteSeleccionada;
