import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../store/AppContext';
import * as XLSX from 'xlsx';
import RenderPatenteTabla from './RenderPatenteTabla';
import ModalPatenteSeleccionada from './ModalPatenteSeleccionada';

const PatentesTodas = ({ onRegresar }) => {
  const { store, actions } = useContext(Context);
  const [modalPatente, setModalPatente] = useState(null);
  const onVista = "Todas";

  useEffect(() => {
    actions.getPatentes(onVista);
  }, []);

  const patentes = store.patentes || [];

  const abrirModal = (patente) => {
    actions.getPatente(patente.id);
    setTimeout(() => {
      setModalPatente(store.patente);
    }, 700);
  };

  const actualizarModal = (patenteId) => {
    actions.getPatente(patenteId);
    setTimeout(() => {
      setModalPatente(store.patente);
    }, 700);
  };

  const cerrarModal = () => {
    setModalPatente(null);
  };

  // Función para transformar los datos
  const transformarDatosParaExcel = (datos) => {
    return datos.map(patente => ({
      ...patente,
      codigo: patente.codigo && patente.codigo.length > 0 ? patente.codigo.map(c => `${c[0]} (${c[1]})`).join(', ') : 'N/A',
      numeroExpediente: patente.numeroExpediente && patente.numeroExpediente.length > 0 ? patente.numeroExpediente.map(n => `${n[0]} (${n[1]})`).join(', ') : 'N/A',
      fechaEdicion: patente.fechaEdicion && patente.fechaEdicion.length > 0 ? patente.fechaEdicion.map(f => `${f[0]} por ${f[1]}`).join(', ') : 'N/A'
    }));
  };

  // Función para exportar a Excel
  const exportarAExcel = () => {
    const datosTransformados = transformarDatosParaExcel(store.patentes);
    const ws = XLSX.utils.json_to_sheet(datosTransformados);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Patentes");
    XLSX.writeFile(wb, "Todas las Patentes.xlsx");
  };

  return (
    <div className="grid-container">
      <div className="grid-x grid-padding-x">
        <div className="cell">
          <button className="button" onClick={onRegresar}>Regresar</button>
          <button 
            className="button success float-right" 
            onClick={exportarAExcel}
            disabled={!patentes.length}
          >
            Exportar a Excel
          </button>
          <h2>Todas las Patentes</h2>
          <RenderPatenteTabla 
            patentes={patentes} 
            onClickFila={abrirModal}
          />
          <ModalPatenteSeleccionada 
            patente={modalPatente} 
            onClose={cerrarModal}
            onUpdate={actualizarModal}
            onVista={onVista}
          />
        </div>
      </div>
    </div>
  );
};

export default PatentesTodas;
