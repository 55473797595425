import React, { useState } from "react";

const Formulario = ({ img, setImg, datos, setDatos, opcion }) => {
	const [fecha, setFecha] = useState(false);
	const handlerImg = (event) => {
		const imgInp = event.target.files[0];
		if (imgInp) {
			setImg(URL.createObjectURL(imgInp));
		}
	};

	const Handler = (event) => {
		setDatos({ ...datos, [event.target.name]: event.target.value });
	};

	const setFechaHoy = () => {
		setFecha(false);
		setDatos({ ...datos, fecha: "hoy" });
	};

	const setFechaExtacta = () => {
		setFecha(true);
		setDatos({ ...datos, fecha: "" });
	};

	return (
		<div className='row'>
			<div className='medium-6 columns'>
				{opcion == "Contrato Contabilidad" ? (
					<div className=''>
						<label className=''>Elige Tipo de Contrato</label>
						<select
							className=''
							id='exampleFormControlSelect1'
							value={datos.planContratado}
							name='planContratado'
							onChange={(e) => Handler(e)}
						>
							<option selected>Elige una opción...</option>
							<option value='Plan Básico'>Plan Básico</option>
							<option value='Plan Emprende'>Plan Emprende</option>
							<option value='Plan Pyme'>Plan Pyme</option>
							<option value='Contrato Remuneraciones'>
								Contrato Remuneraciones
							</option>
						</select>
					</div>
				) : null}
				<label>
					Nombre Representante
					<input
						type='text'
						placeholder='Nombre Completo Chacarero Hamburguesa'
						value={datos.nombre}
						name='nombre'
						onChange={(e) => Handler(e)}
					/>
				</label>
				<label>
					Rut de Representante Legal
					<input
						type='text'
						placeholder='17.123.123-K'
						value={datos.rut}
						name='rut'
						onChange={(e) => Handler(e)}
					/>
				</label>
				<label>
					Razón Social Empresa
					<input
						type='text'
						placeholder='Nombre Completo SpA'
						value={datos.razon}
						name='razon'
						onChange={(e) => Handler(e)}
					/>
				</label>
				<label>
					Rut Empresa
					<input
						type='text'
						placeholder='76.717.904-9'
						value={datos.rutEmpresa}
						name='rutEmpresa'
						onChange={(e) => Handler(e)}
					/>
				</label>
				<div className=''>
					<label className=''>Elige Nacionalidad</label>
					<select
						className=''
						id='exampleFormControlSelect1'
						value={datos.nacionalidad}
						name='nacionalidad'
						onChange={(e) => Handler(e)}
					>
						<option selected>Elige una opción...</option>
						<option value='Chilena'>Chilena</option>
						<option value='Extranjera'>Extranjera</option>
					</select>
				</div>
				<div>
					<label>Fecha</label>
					<input
						type='radio'
						id='html'
						name='fav_language'
						value='hoy'
						onClick={(e) => setFechaHoy(e)}
					/>
					<label for='html'>HOY</label>
					<input
						type='radio'
						id='html'
						name='fav_language'
						value='fecha exacta'
						onClick={(e) => setFechaExtacta(e)}
					/>
					<label for='html'>FECHA EXACTA</label>
				</div>
				{fecha ? (
					<label>
						Fecha
						<input
							type='text'
							placeholder='4 de Agosto de 1588'
							value={datos.fecha}
							name='fecha'
							onChange={(e) => Handler(e)}
						/>
					</label>
				) : null}
				{opcion == "Contrato Contabilidad" ? null : (
					<div className='Prueba'>
						<label className=''>Sube Cédula de Identidad</label>
						<input
							className=''
							accept='image/*'
							type='file'
							id='imgInp'
							onChange={handlerImg}
						/>
					</div>
				)}
				{opcion == "Contrato Arriendo Mensual" || opcion == "Contrato Arriendo Anual" || opcion == "Contrato Arriendo 30 dias" ? <div><label>
						Correo Electrónico*
						<input
							type='email'
							placeholder='correo@gmail.com'
							value={datos.correo}
							name='correo'
							required
							onChange={(e) => Handler(e)}
						/>
					</label><label>
						Teléfono de Contacto*
						<input
							type='text'
							placeholder='+56953897615'
							value={datos.telefono}
							name='telefono'
							required
							onChange={(e) => Handler(e)}
						/>
					</label></div> : null}
				<br></br>
			</div>
			<div className='medium-6 columns ImagenCedula'>
				{opcion == "Contrato Contabilidad" ? null : (
					<div>
						<h2>Cédula de Identidad</h2>
						<img
							id='blah'
							src={img}
							alt='Sube una Cedula y Previsualizala'
							style={{ height: "400px" }}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default Formulario;
