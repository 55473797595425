import React, { Fragment, useContext, useState, useEffect } from "react";
import { Context } from "../../store/AppContext";
import RenderPatenteTabla from "./RenderPatenteTabla";
import ModalPatenteSeleccionada from "./ModalPatenteSeleccionada";

const BusquedaIndividual = ({ onRegresar }) => {
	const { store, actions } = useContext(Context);
	const [animar, setAnimar] = useState(false);
	const [busqueda, setBusqueda] = useState('');
	const [mostrarResultados, setMostrarResultados] = useState(false);
	const [modalPatente, setModalPatente] = useState(null);

	//Animacion
	useEffect(() => {
		setAnimar(true);
	}, []);

	const HandlerCerrar = () => {
		setAnimar(false);
		setTimeout(() => {
			onRegresar();
		}, 500);
	};

	const HandlerOnChange = (event) => {
		setBusqueda(event.target.value);
		setMostrarResultados(false);
	};

	const HandlerOnPress = (event) => {
		if (event.key === "Enter") {
			HandlerBuscar();
		}
	};

	const HandlerBuscar = async () => {
		if (busqueda.trim()) {
			const resultado = await actions.getBusquedaIndividualPatente(busqueda);
			setMostrarResultados(true);
		}
	};

	const abrirModal = (patente) => {
		actions.getPatente(patente.id);
		setTimeout(() => {
		setModalPatente(store.patente);
		}, 700);
	};

	const actualizarModal = (patenteId) => {
		actions.getPatente(patenteId);
		setTimeout(() => {
		setModalPatente(store.patente);
		}, 700);
	};

	const cerrarModal = () => {
		setModalPatente(null);
	};

	return (
		<div className={`divGenerador ${animar ? "animar" : "noAnimar"}`}>
			<div className='row button-group align-right'>
				<button className='button secondary' onClick={HandlerCerrar}>
					Regresar
				</button>
			</div>
			<div className='row'>
				<Fragment>
					<div className='row'>
						<div
							className='grid-x grid-margin-x'
							style={{
								boxShadow: "0px 4px 8px #000000",
								paddingTop: "20px",
								paddingBottom: "5px",
							}}
						>
							<div className='cell small-10'>
								<div className='grid-x grid-margin-x'>
									<input
										className='cell small-12'
										type='text'
										placeholder='Ingresa tu Búsqueda'
										name='Buscador'
										value={busqueda}
										onChange={HandlerOnChange}
										onKeyDown={HandlerOnPress}
									/>
								</div>
							</div>
							<div className='cell small-1 text-right'>
								<a
									className='clear button secondary'
									onClick={HandlerBuscar}
								>
									Buscar
								</a>
							</div>
						</div>
						<br />
						<hr />
						{mostrarResultados && (
							<div className='row'>
								{store.patentesEncontradas.length > 0 ? (
									<>
										<h4>Resultados de la búsqueda:</h4>
										<RenderPatenteTabla 
											patentes={store.patentesEncontradas}
											onClickFila={abrirModal}
										/>
										<ModalPatenteSeleccionada 
											patente={modalPatente} 
											onClose={cerrarModal}
											onUpdate={actualizarModal}
											onVista={"Busqueda"}
											busqueda={busqueda}
										/>
									</>
								) : (
									<div className="callout warning">
										<h5>No se encontraron resultados</h5>
										<p>Intenta con otros términos de búsqueda.</p>
									</div>
								)}
							</div>
						)}
					</div>
				</Fragment>
			</div>
		</div>
	);
};

export default BusquedaIndividual;
