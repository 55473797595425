import React, { useState, useEffect, useContext } from 'react';
import { Context } from "../../store/AppContext";
import ModalPatenteSeleccionada from './ModalPatenteSeleccionada';
import RenderPatenteTabla from './RenderPatenteTabla';

const PatentesEnTramite = ({ onRegresar }) => {
  const { store, actions } = useContext(Context);
  const [modalPatente, setModalPatente] = useState(null);
  const onVista = "Muertas";

  useEffect(() => {
    actions.getPatentes(onVista);
  }, []);

  const abrirModal = (patente) => {
    actions.getPatente(patente.id);
    setTimeout(() => {
      setModalPatente(store.patente);
    }, 700);
  };

  const actualizarModal = (patenteId) => {
    actions.getPatente(patenteId);
    setTimeout(() => {
      setModalPatente(store.patente);
    }, 700);
  };

  const cerrarModal = () => {
    setModalPatente(null);
  };

  // Filtrar las patentes excluyendo las "Terminadas"
  const patentesFiltradas = store.patentes ? store.patentes.filter(patente => patente.estado === "Devolucion" || patente.estado === "Cliente No Responde" || patente.estado === "No Sigue Proceso") : [];

  const renderTabla = () => (
    <RenderPatenteTabla 
      patentes={patentesFiltradas} 
      onClickFila={abrirModal}
    />
  );
  return (
    <div>
      <div className="grid-x grid-padding-x">
        <div className="cell">
          <div className="button-group">
            <button className="button" onClick={onRegresar}>Regresar</button>
          </div>
          <h2>Patentes Muertas</h2>
          {renderTabla()}
          <ModalPatenteSeleccionada 
            patente={modalPatente} 
            onClose={cerrarModal}
            onUpdate={actualizarModal}
            onVista={onVista}
          />
        </div>
      </div>
    </div>
  );
};

export default PatentesEnTramite;
