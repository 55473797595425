// http://127.0.0.1:500x0 --> https://denegocios.herokxuapp.com
const getState = ({ getStore, getActions, setStore }) => {
	function sortByMonth(arr) {
		//Esta funcion permite ordenar los meses de los pagos. Ver cada getPago2019
		const meses = [
			"Diciembre",
			"Noviembre",
			"Octubre",
			"Septiembre",
			"Agosto",
			"Julio",
			"Junio",
			"Mayo",
			"Abril",
			"Marzo",
			"Febrero",
			"Enero",
		];
		arr.sort(function (a, b) {
			return meses.indexOf(a.mes) - meses.indexOf(b.mes);
		});
	}

	const fechaSessionStorage = () => {
		const fecha = new Date();
		const opciones = {
			year: "numeric",
			month: "long",
			day: "2-digit",
		};
		return `${fecha.toLocaleDateString(
			"es-ES",
			opciones
		)} a las ${fecha.toLocaleTimeString()}`;
	};

	return {
		store: {
			witch: true, //Setea existencia de barra lateral izquierda
			usuarios: null,
			usuario: null,
			response: null,
			clientesDt: [{}], //null, //Lista de Clientes de Dt
			paginasClientesDt: null, //Total de Paginas para los Clientes Dt
			paginaActualClientesDt: null, //Total Clientes en Dt
			infoClienteDt: null, //ClienteDt Seleccionado
			nota: null, //Notas especificas al id del ClienteDt seleccionado
			pago2019: null, //Pagos especificos al id del ClienteDt seleccionado
			pago2020: null, //Pagos especificos al id del ClienteDt seleccionado
			pago2021: null, //Pagos especificos al id del ClienteDt seleccionado
			pago2022: null, //Pagos especificos al id del ClienteDt seleccionado
			pago2023: null, //Pagos especificos al id del ClienteDt seleccionado
			pago2024: null, //Pagos especificos al id del ClienteDt seleccionado
			pago2025: null, //Pagos especificos al id del ClienteDt seleccionado
			pago2026: null, //Pagos especificos al id del ClienteDt seleccionado
			pago2027: null, //Pagos especificos al id del ClienteDt seleccionado
			usuarioActual:
				JSON.parse(localStorage.getItem("usuarioActual")) === null
					? null
					: JSON.parse(localStorage.getItem("usuarioActual")), //Usuario Actual que está Conectado
			token:
				localStorage.getItem("token") === null
					? null
					: localStorage.getItem("token"), //Token del Usuario Actual Conectado
			paginaActual:
				sessionStorage.getItem("paginaActual") === null
					? null
					: sessionStorage.getItem("paginaActual"),
			spinner: false,
			clientesContabilidad: null,
			paginasClientesContabilidad: null,
			paginaActualClientesContabilidad: null,
			infoClienteContabilidad: null,
			notaContabilidad: null,
			pagoContabilidad: null,
			ventasUsuario: null,
			ventasUsuarioVendedores: null,
			ventasUsuarioPorId: null,
			ventasUsuarioEstadisticas: null,
			patentes: null,
			patente: null,
			patentesEncontradas: [], // Nueva propiedad para almacenar resultados de búsqueda
		},

		actions: {
			//setWitch cambia la barra lateral izquierda.
			setWitch: () => {
				const store = getStore();
				
				store.witch ? setStore({ witch: false }) : setStore({ witch: true });
			},

			setPaginaActual: (paginaActual) => {
				sessionStorage.setItem("paginaActual", paginaActual);
				setStore({ paginaActual: paginaActual });
			},

			setVentaUsuariostoNull: () => {
				setStore({
					ventasUsuario: null,
				});
			},

			setSpinner: () => {
				const store = getStore();
				store.spinner
					? setStore({ spinner: false })
					: setStore({ spinner: true });
			},

			/* USUARIOS Y LOGIN */
			loginUsuario: async (correo, clave) => {
				const actions = getActions();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/login", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ correo: correo, clave: clave }),
				})
					.then((res) => {
						if (res.status === 200) return res.json();
						else if (res.status === 401) {
							alert("Usuario o clave Incorrecto");
							setStore({ spinner: false });
						} else if (res.status === 500) {
							//BD debería mandar un mensaje que indique que usuario no está, más que 500 response.
							alert("Hay un problema, revisa tus credenciales");
							setStore({ spinner: false });
						}
					})
					.then((data) => {
						localStorage.setItem("usuarioActual", JSON.stringify(data[0]));
						localStorage.setItem("token", data[1]);
						setStore({ usuarioActual: data[0], token: data[1] });
						setStore({ spinner: false });
					})
					.catch((error) => {
						console.error("Hay un problemilla", error);
					});
			},

			getUsuario: async (usuarioid) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/usuario/" + usuarioid, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							usuario: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: "usuario seleccionado " + error.message,
						});
					});
			},

			getUsuarios: async () => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/usuario", {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							usuarios: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: "usuarios " + error.message,
						});
					});
			},

			crearUsuario: async (nombre, apellido, correo, clave, tipo) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/usuario", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token, //revisar
					},
					body: JSON.stringify({
						nombre: nombre,
						apellido: apellido,
						correo: correo,
						clave: clave,
						tipo: tipo,
						fechaCreacion: fechaSessionStorage(),
						fechaModificacion: fechaSessionStorage(),
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			editarUsuario: async (id, nombre, apellido, correo, clave, tipo) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/usuario/" + id, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						nombre: nombre === "" ? null : nombre,
						apellido: apellido === "" ? null : apellido,
						correo: correo === "" ? null : correo,
						clave: clave === "" ? null : clave,
						tipo: tipo === "" ? null : tipo,
						fechaModificacion: fechaSessionStorage(),
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			borrarUsuario: async (id) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/usuario/" + id, {
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			/* CLIENTES DIRECCION TRIBUTARIA */

			/* Si */
			/* Busqueda con Usuario Normal y Listas de Cobranzas */
			getBusquedaDt: async (busqueda, tipoDato = "Nada") => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/busquedaDt", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						busqueda: busqueda,
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							clientesDt: data[0],
							paginasClientesDt: data[1],
							paginaActualClientesDt: data[2],
						});
						if (
							tipoDato === "Con Deuda" ||
							tipoDato === "Sin Deuda" ||
							tipoDato === "Todo" ||
							tipoDato === "Vigente" ||
							tipoDato === "No Vigente" ||
							tipoDato === "Con Patente" ||
							tipoDato === "Sin Patente" ||
							tipoDato === "Con DICOM" ||
							tipoDato === "Sin DICOM"
						) {
							sessionStorage.setItem(
								"listadoClientesDt",
								JSON.stringify(data[0])
							);
							sessionStorage.setItem("dateClientesDt", fechaSessionStorage());
							/* Lo siguiente setea la fecha para almacenar y correr esta action */
							sessionStorage.setItem(
								"dateClientesDtLlamada",
								JSON.stringify(new Date().toLocaleDateString())
							);
						}
						setTimeout(() => {
							setStore({ spinner: false });
						}, 1000);
					})
					.catch((error) => {
						setStore({
							error: "clientesDT " + error.message,
						});
					});
			},
			/* Si */
			getClienteDt: async (clienteDtid) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/clienteDt/" + clienteDtid, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							infoClienteDt: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: "clientesDT " + error.message,
						});
					});
			},
			/* Si */
			crearClienteDt: async (
				razon,
				rut,
				vigente,
				correo,
				correoSecundario,
				correoTerciario,
				fono,
				whatsapp,
				representante,
				rutRepresentante,
				fechaContratacion,
				erpyme,
				p,
				sacar,
				dicom,
				contratoActualizado,
				patente,
				libre,
				mesesPagados,
				mesesPagados2023,
				tipoPago
			) => {
				const store = getStore();
				setStore({ spinner: true });
				setStore({
					response: null,
				});
				fetch("https://denegocios.herokuapp.com/clienteDt", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						razon: razon,
						rut: rut,
						vigente: vigente,
						correo: correo,
						correoSecundario: correoSecundario,
						correoTerciario: correoTerciario,
						fono: fono,
						whatsapp: whatsapp,
						representante: representante,
						rutRepresentante: rutRepresentante,
						fechaContratacion: fechaContratacion,
						erpyme: erpyme,
						p: p,
						sacar: sacar,
						dicom: dicom,
						contratoActualizado: contratoActualizado,
						patente: patente,
						libre: libre,
						mesesPagados: mesesPagados,
						mesesPagados2023: mesesPagados2023,
						tipoPago: tipoPago,
						fechaCreacion: fechaSessionStorage(),
						fechaModificacion: fechaSessionStorage(),
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},
			/* Si */
			editarClienteDt: async (
				id,
				razon,
				rut,
				vigente,
				correo,
				correoSecundario,
				correoTerciario,
				fono,
				whatsapp,
				representante,
				rutRepresentante,
				fechaContratacion,
				erpyme,
				p,
				sacar,
				dicom,
				contratoActualizado,
				patente,
				libre,
				mesesPagados,
				mesesPagados2023,
				tipoPago
			) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/clienteDt/" + id, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						razon: razon === "" ? null : razon,
						rut: rut === "" ? null : rut,
						vigente: vigente === "" ? null : vigente,
						correo: correo === "" ? null : correo,
						correoSecundario: correoSecundario === "" ? null : correoSecundario,
						correoTerciario: correoTerciario === "" ? null : correoTerciario,
						fono: fono === "" ? null : fono,
						whatsapp: whatsapp === "" ? null : whatsapp,
						representante: representante === "" ? null : representante,
						rutRepresentante: rutRepresentante === "" ? null : rutRepresentante,
						fechaContratacion:
							fechaContratacion === "" ? null : fechaContratacion,
						erpyme: erpyme === "" ? null : erpyme,
						p: p === "" ? null : p,
						sacar: sacar === "" ? null : sacar,
						dicom: dicom === "" ? null : dicom,
						contratoActualizado:
							contratoActualizado === "" ? null : contratoActualizado,
						patente: patente === "" ? null : patente,
						libre: libre === "" ? null : libre,
						mesesPagados: mesesPagados === "" ? null : mesesPagados,
						mesesPagados2023: mesesPagados2023 === "" ? null : mesesPagados2023,
						tipoPago: tipoPago === "" ? null : tipoPago,
						fechaModificacion: fechaSessionStorage(),
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},
			/* Si */
			editarMesesPagados2023ClienteDt: async (id, mesesPagados2023) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/clienteDt/" + id, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						razon: null,
						rut: null,
						vigente: null,
						correo: null,
						correoSecundario: null,
						correoTerciario: null,
						fono: null,
						whatsapp: null,
						representante: null,
						rutRepresentante: null,
						fechaContratacion: null,
						erpyme: null,
						p: null,
						sacar: null,
						dicom: null,
						contratoActualizado: null,
						patente: null,
						libre: null,
						mesesPagados: null,
						mesesPagados2023: mesesPagados2023,
						tipoPago: null,
						fechaModificacion: fechaSessionStorage(),
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},
			/* Si */
			editarMesesPagadosClienteDt: async (id, mesesPagados) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/clienteDt/" + id, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						razon: null,
						rut: null,
						vigente: null,
						correo: null,
						correoSecundario: null,
						correoTerciario: null,
						fono: null,
						whatsapp: null,
						representante: null,
						rutRepresentante: null,
						fechaContratacion: null,
						erpyme: null,
						p: null,
						sacar: null,
						dicom: null,
						contratoActualizado: null,
						patente: null,
						libre: null,
						mesesPagados: mesesPagados,
						mesesPagados2023: null,
						tipoPago: null,
						fechaModificacion: fechaSessionStorage(),
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			// /* Notas Direccion Tributaria*/
			getNota: async (clienteDtid) => {
				const store = getStore();
				fetch("https://denegocios.herokuapp.com/nota/" + clienteDtid, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							nota: data,
						});
					})
					.catch((error) => {
						setStore({
							error: "clientesDT " + error.message,
						});
					});
			},

			crearNota: async (comentario, fechaComentario, clienteDtid) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/nota", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						comentario: comentario,
						fechaComentario: fechaComentario,
						clienteDtid: clienteDtid,
						fechaCreacion: fechaSessionStorage(),
						fechaModificacion: fechaSessionStorage(),
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			//  /* Pagos Direccion Tributaria */

			getPago2019: async (clienteDtid) => {
				const store = getStore();
				fetch("https://denegocios.herokuapp.com/dt2019/" + clienteDtid, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						sortByMonth(data);
						setStore({
							pago2019: data,
						});
					})
					.catch((error) => {
						setStore({
							error: "clientesDT " + error.message,
						});
					});
			},

			getPago2020: async (clienteDtid) => {
				const store = getStore();
				fetch("https://denegocios.herokuapp.com/dt2020/" + clienteDtid, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						sortByMonth(data);
						setStore({
							pago2020: data,
						});
					})
					.catch((error) => {
						setStore({
							error: "clientesDT " + error.message,
						});
					});
			},

			getPago2021: async (clienteDtid) => {
				const store = getStore();
				fetch("https://denegocios.herokuapp.com/dt2021/" + clienteDtid, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						sortByMonth(data);
						setStore({
							pago2021: data,
						});
					})
					.catch((error) => {
						setStore({
							error: "clientesDT " + error.message,
						});
					});
			},

			getPago2022: async (clienteDtid) => {
				const store = getStore();
				fetch("https://denegocios.herokuapp.com/dt2022/" + clienteDtid, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						sortByMonth(data);
						setStore({
							pago2022: data,
						});
					})
					.catch((error) => {
						setStore({
							error: "clientesDT " + error.message,
						});
					});
			},

			getPago2023: async (clienteDtid) => {
				const store = getStore();
				fetch("https://denegocios.herokuapp.com/dt2023/" + clienteDtid, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						sortByMonth(data);
						setStore({
							pago2023: data,
						});
					})
					.catch((error) => {
						setStore({
							error: "clientesDT " + error.message,
						});
					});
			},

			getPago2024: async (clienteDtid) => {
				const store = getStore();
				fetch("https://denegocios.herokuapp.com/dt2024/" + clienteDtid, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						sortByMonth(data);
						setStore({
							pago2024: data,
						});
					})
					.catch((error) => {
						setStore({
							error: "clientesDT " + error.message,
						});
					});
			},

			getPago2025: async (clienteDtid) => {
				const store = getStore();
				fetch("https://denegocios.herokuapp.com/dt2025/" + clienteDtid, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						sortByMonth(data);
						setStore({
							pago2025: data,
						});
					})
					.catch((error) => {
						setStore({
							error: "clientesDT " + error.message,
						});
					});
			},

			getPago2026: async (clienteDtid) => {
				const store = getStore();
				fetch("https://denegocios.herokuapp.com/dt2026/" + clienteDtid, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						sortByMonth(data);
						setStore({
							pago2026: data,
						});
					})
					.catch((error) => {
						setStore({
							error: "clientesDT " + error.message,
						});
					});
			},

			getPago2027: async (clienteDtid) => {
				const store = getStore();
				fetch("https://denegocios.herokuapp.com/dt2027/" + clienteDtid, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						sortByMonth(data);
						setStore({
							pago2027: data,
						});
					})
					.catch((error) => {
						setStore({
							error: "clientesDT " + error.message,
						});
					});
			},

			crearPago: async (
				year,
				mes,
				numeroTransferencia,
				montoPagado,
				montoCobrado,
				mesesPagados,
				mesesPagados2023,
				facturaNumero,
				comentario,
				fechaIngresoPago,
				clienteDtid
			) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/dt" + year, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						mes: mes,
						numeroTransferencia: numeroTransferencia,
						montoPagado: montoPagado,
						montoCobrado: montoCobrado,
						mesesPagados: mesesPagados,
						mesesPagados2023: mesesPagados2023,
						facturaNumero: facturaNumero,
						comentario: comentario,
						fechaIngresoPago: fechaIngresoPago,
						clienteDtid: clienteDtid,
						fechaCreacion: fechaSessionStorage(),
						fechaModificacion: fechaSessionStorage(),
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			editarPago: async (
				year,
				mes,
				numeroTransferencia,
				montoPagado,
				montoCobrado,
				mesesPagados,
				mesesPagados2023,
				facturaNumero,
				comentario,
				clienteDtid
			) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch(
					"https://denegocios.herokuapp.com/dt" + year + "/" + clienteDtid,
					{
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + store.token,
						},
						body: JSON.stringify({
							mes: mes === "" ? null : mes,
							numeroTransferencia:
								numeroTransferencia === "" ? null : numeroTransferencia,
							montoPagado: montoPagado === "" ? null : montoPagado,
							montoCobrado: montoCobrado === "" ? null : montoCobrado,
							mesesPagados: mesesPagados === "" ? null : mesesPagados,
							mesesPagados2023:
								mesesPagados2023 === "" ? null : mesesPagados2023,
							facturaNumero: facturaNumero === "" ? null : facturaNumero,
							comentario: comentario === "" ? null : comentario,
							fechaModificacion: fechaSessionStorage(),
						}),
					}
				)
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},
			/* Si */
			borrarPago: async (year, clienteDtid) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch(
					"https://denegocios.herokuapp.com/dt" + year + "/" + clienteDtid,
					{
						method: "DELETE",
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + store.token,
						},
					}
				)
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			/* CLIENTES DE CONTABILIDAD */

			getClientesContabilidad: async (page_num) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/xContabilidad/" + page_num, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							clientesContabilidad: data[0],
							paginasClientesContabilidad: data[1],
							paginaActualClientesContabilidad: data[2],
						});						
						setTimeout(() => {
							setStore({ spinner: false });
						}, 1000);
					})
					.catch((error) => {
						setStore({
							error: "clientesContabilidad " + error.message,
						});
					});
			},
			/* Este se supone que es para barra de busqueda de clientes contabilidad */
			getBusquedaContabilidad: async (busqueda, tipoDato = "Nada") => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/busquedaContabilidad", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						busqueda: busqueda,
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							clientesContabilidad: data[0],
							paginasClientesContabilidad: data[1],
							paginaActualClientesContabilidad: data[2],
						});
						setTimeout(() => {
							setStore({ spinner: false });
						}, 1000);
					})
					.catch((error) => {
						setStore({
							error: "clientesContabilidad " + error.message,
						});
					});
			},
			/* No se está usando */
			getBusquedaFiltroContabilidad: async (
				vigente,
				whatsapp,
				erpyme,
				dicom,
				repetido
			) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/filtroContabilidad", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						vigente: vigente === "Selecciona..." ? null : vigente,
						whatsapp: whatsapp === "Selecciona..." ? null : whatsapp,
						erpyme: erpyme === "Selecciona..." ? null : erpyme,
						dicom: dicom === "Selecciona..." ? null : dicom,
						repetido: repetido === "Selecciona..." ? null : repetido,
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							clientesContabilidad: data[0],
							paginasClientesContabilidad: data[1],
							paginaActualClientesContabilidad: data[2],
						});
						setTimeout(() => {
							setStore({ spinner: false });
						}, 1000);
					})
					.catch((error) => {
						setStore({
							error: "clientesContabilidad " + error.message,
						});
					});
			},

			getClienteContabilidad: async (clienteContabilidadid) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch(
					"https://denegocios.herokuapp.com/clienteContabilidad/" +
						clienteContabilidadid,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + store.token,
						},
					}
				)
					.then((response) => response.json())
					.then((data) => {
						setStore({
							infoClienteContabilidad: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: "clientesContabilidad " + error.message,
						});
					});
			},

			crearClienteContabilidad: async (
				razon,
				rut,
				vigente,
				correo,
				correoSecundario,
				correoTerciario,
				fono,
				whatsapp,
				erpyme,
				dicom,
				repetido,
				libre
			) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/clienteContabilidad", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						razon: razon,
						rut: rut,
						vigente: vigente,
						correo: correo,
						correoSecundario: correoSecundario,
						correoTerciario: correoTerciario,
						fono: fono,
						whatsapp: whatsapp,
						erpyme: erpyme,
						dicom: dicom,
						repetido: repetido,
						libre: libre,
						fechaCreacion: fechaSessionStorage(),
						fechaModificacion: fechaSessionStorage(),
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			editarClienteContabilidad: async (
				id,
				razon,
				rut,
				vigente,
				correo,
				correoSecundario,
				correoTerciario,
				fono,
				whatsapp,
				erpyme,
				dicom,
				repetido,
				libre
			) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/clienteContabilidad/" + id, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						razon: razon === "" ? null : razon,
						rut: rut === "" ? null : rut,
						vigente: vigente === "" ? null : vigente,
						correo: correo === "" ? null : correo,
						correoSecundario: correoSecundario === "" ? null : correoSecundario,
						correoTerciario: correoTerciario === "" ? null : correoTerciario,
						fono: fono === "" ? null : fono,
						whatsapp: whatsapp === "" ? null : whatsapp,
						erpyme: erpyme === "" ? null : erpyme,
						dicom: dicom === "" ? null : dicom,
						repetido: repetido === "" ? null : repetido,
						libre: libre === "" ? null : libre,
						fechaModificacion: fechaSessionStorage(),
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			// /* Notas Contabilidad*/
			getNotaContabilidad: async (clienteContabilidadid) => {
				const store = getStore();
				fetch(
					"https://denegocios.herokuapp.com/notaContabilidad/" +
						clienteContabilidadid,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + store.token,
						},
					}
				)
					.then((response) => response.json())
					.then((data) => {
						setStore({
							notaContabilidad: data,
						});
					})
					.catch((error) => {
						setStore({
							error: "clientesContabilidad " + error.message,
						});
					});
			},

			crearNotaContabilidad: async (
				comentario,
				fechaComentario,
				clienteContabilidadid
			) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/notaContabilidad", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						comentario: comentario,
						fechaComentario: fechaComentario,
						clienteContabilidadid: clienteContabilidadid,
						fechaCreacion: fechaSessionStorage(),
						fechaModificacion: fechaSessionStorage(),
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			//  /* Pagos Contabilidad */

			getPagoContabilidad: async (clienteContabilidadid) => {
				////Aquí hay que ordenar adicionalmente por Year
				const store = getStore();			
				fetch(
					"https://denegocios.herokuapp.com/pagosContabilidad/" +
						clienteContabilidadid,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + store.token,
						},
					}
				)
					.then((response) => response.json())
					.then((data) => {
						setStore({
							pagoContabilidad: data,
						});
					})
					.catch((error) => {
						setStore({
							error: "clientesContabilidad" + error.message,
						});
					});					
			},

			crearPagoContabilidad: async (
				year,
				mes,
				numeroTransferencia,
				montoPagado,
				montoCobrado,
				facturaNumero,
				comentario,
				clienteContabilidadid
			) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/pagosContabilidad", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						year: year,
						mes: mes,
						numeroTransferencia: numeroTransferencia,
						montoPagado: montoPagado,
						montoCobrado: montoCobrado,
						facturaNumero: facturaNumero,
						comentario: comentario,
						clienteContabilidadid: clienteContabilidadid,
						fechaCreacion: fechaSessionStorage(),
						fechaModificacion: fechaSessionStorage(),
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			editarPagoContabilidad: async (
				year,
				mes,
				numeroTransferencia,
				montoPagado,
				montoCobrado,
				facturaNumero,
				comentario,
				clienteContabilidadid
			) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch(
					"https://denegocios.herokuapp.com/pagosContabilidad/" +
						clienteContabilidadid,
					{
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + store.token,
						},
						body: JSON.stringify({
							year: year === "" ? null : year,
							mes: mes === "" ? null : mes,
							numeroTransferencia:
								numeroTransferencia === "" ? null : numeroTransferencia,
							montoPagado: montoPagado === "" ? null : montoPagado,
							montoCobrado: montoCobrado === "" ? null : montoCobrado,
							facturaNumero: facturaNumero === "" ? null : facturaNumero,
							comentario: comentario === "" ? null : comentario,
							fechaModificacion: fechaSessionStorage(),
						}),
					}
				)
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			borrarPagoContabilidad: async (clienteContabilidadid) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch(
					"https://denegocios.herokuapp.com/pagosContabilidad/" +
						clienteContabilidadid,
					{
						method: "DELETE",
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + store.token,
						},
					}
				)
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			/* Ventas Usuarios */

			getVentasUsuario: async () => {
				const store = getStore();
				fetch("https://denegocios.herokuapp.com/ventaUsuarios", {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							ventasUsuario: data,
						});
					})
					.catch((error) => {
						setStore({
							error: "ventasUsuarios" + error.message,
						});
					});
			},

			getVentasUsuarioPorID: async (id) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/ventaUsuarios/" + id, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							ventasUsuarioPorId: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: "ventasUsuarios" + error.message,
						});
					});
			},

			getVentaUsuarioEspecifico: async (usuarioid) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/ventaUsuarioEspecifico", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						usuarioid: usuarioid,
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							ventasUsuarioVendedores: data,
						});
						sessionStorage.setItem("dateVentasMeses", true);
						{
							data[0] && sessionStorage.setItem("ventasMes0", data[0].ventas);
						}
						{
							data[1] && sessionStorage.setItem("ventasMes1", data[1].ventas);
						}
						{
							data[2] && sessionStorage.setItem("ventasMes2", data[2].ventas);
						}
						{
							data[3] && sessionStorage.setItem("ventasMes3", data[3].ventas);
						}
						{
							data[4] && sessionStorage.setItem("ventasMes4", data[4].ventas);
						}
						{
							data[5] && sessionStorage.setItem("ventasMes5", data[5].ventas);
						}
						{
							data[6] && sessionStorage.setItem("ventasMes6", data[6].ventas);
						}
						{
							data[7] && sessionStorage.setItem("ventasMes7", data[7].ventas);
						}
						{
							data[8] && sessionStorage.setItem("ventasMes8", data[8].ventas);
						}
						{
							data[9] && sessionStorage.setItem("ventasMes9", data[9].ventas);
						}
						{
							data[10] &&
								sessionStorage.setItem("ventasMes10", data[10].ventas);
						}
						{
							data[11] &&
								sessionStorage.setItem("ventasMes11", data[11].ventas);
						}
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: "ventasUsuarios" + error.message,
						});
					});
			},

			getVentasUsuariosModificarIngresoDatos: async (
				yearIngreso,
				mesIngreso
			) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch(
					"https://denegocios.herokuapp.com/getVentasUsuariosModificarIngresoDatos",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + store.token,
						},
						body: JSON.stringify({
							yearIngreso: yearIngreso === "Selecciona..." ? null : yearIngreso,
							mesIngreso: mesIngreso === "Selecciona..." ? null : mesIngreso,
						}),
					}
				)
					.then((response) => response.json())
					.then((data) => {
						setStore({
							ventasUsuario: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: "ventasUsuarios modificacion datos" + error.message,
						});
					});
			},

			crearVentaEspecifica: async (
				yearIngreso,
				mesIngreso,
				ventas,
				ventasTotales,
				ventasTotalesPasado,
				kpi,
				gastoAds,
				totalPosibles,
				adsPorVendedor,
				posiblesPorVendedor,
				facturasPendientes,
				sueldoBase,
				comisionBase,
				sueldoLiquido,
				id,
				nombre
			) => {
				const store = getStore();
				fetch("https://denegocios.herokuapp.com/ventaUsuarios", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						yearIngreso: yearIngreso,
						mesIngreso: mesIngreso,
						ventas: ventas,
						ventasTotales: ventasTotales,
						ventasTotalesPasado: ventasTotalesPasado,
						kpi: kpi,
						gastoAds: gastoAds,
						totalPosibles: totalPosibles,
						adsPorVendedor: adsPorVendedor,
						posiblesPorVendedor: posiblesPorVendedor,
						facturasPendientes: facturasPendientes,
						sueldoBase: sueldoBase,
						comisionBase: comisionBase,
						sueldoLiquido: sueldoLiquido,
						usuarioid: id,
						nombre: nombre,
						fechaCreacion: fechaSessionStorage(),
						fechaModificacion: fechaSessionStorage(),
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			editarVentaEspecifica: async (
				id,
				mesIngreso = null,
				yearIngreso = null,
				ventas,
				ventasTotales,
				ventasTotalesPasado,
				kpi,
				gastoAds,
				totalPosibles,
				adsPorVendedor,
				posiblesPorVendedor,
				facturasPendientes,
				sueldoBase,
				comisionBase,
				sueldoLiquido
			) => {
				const store = getStore();
				fetch("https://denegocios.herokuapp.com/ventaUsuarios/" + id, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						mesIngreso: mesIngreso,
						yearIngreso: yearIngreso,
						ventas: ventas,
						ventasTotales: ventasTotales,
						ventasTotalesPasado: ventasTotalesPasado,
						kpi: kpi,
						gastoAds: gastoAds,
						totalPosibles: totalPosibles,
						adsPorVendedor: adsPorVendedor,
						posiblesPorVendedor: posiblesPorVendedor,
						facturasPendientes: facturasPendientes,
						sueldoBase: sueldoBase,
						comisionBase: comisionBase,
						sueldoLiquido: sueldoLiquido,
						nombre: null,
						usuarioid: null,
						fechaModificacion: fechaSessionStorage(),
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			getEstadisticasVentas: async (yearIngreso, mesIngreso) => {
				const store = getStore();
				fetch("https://denegocios.herokuapp.com/getEstadisticasVentas", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify({
						yearIngreso: yearIngreso,
						mesIngreso: mesIngreso,
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							ventasUsuarioEstadisticas: data,
						});
					})
					.catch((error) => {
						setStore({
							error: "ventasUsuarios modificacion datos" + error.message,
						});
					});
			},

			/* Patentes */

			getPatentes: async (estado = null) => {
				const store = getStore();
				setStore({ spinner: true });
				
				try {
					const response = await fetch("https://denegocios.herokuapp.com/patentesSegunEstado", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + store.token,
						},
						body: JSON.stringify({ estado: estado }),
					});

					const data = await response.json();
					
					// Función auxiliar para procesar strings JSON de manera segura
					const procesarJSON = (str) => {
						if (!str) return [];
						try {
							return JSON.parse(str.replace(/'/g, '"'));
						} catch (e) {
							console.warn("Error procesando JSON:", e);
							return [];
						}
					};

					// Procesar los datos de manera más segura
					const patentesProcessed = data.map(patente => ({
						...patente,
						codigo: procesarJSON(patente.codigo),
						fechaEdicion: procesarJSON(patente.fechaEdicion),
						numeroExpediente: procesarJSON(patente.numeroExpediente)
					}));

					setStore({
						patentes: patentesProcessed,
					});
				} catch (error) {
					console.error('Error en getPatentes:', error);
					setStore({
						error: "patentes " + error.message,
						patentes: [] // Establecer un valor por defecto
					});
				} finally {
					setStore({ spinner: false });
				}
			},

			getPatente: async (id) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/patentes/" + id, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						// Convertir los datos antes de almacenarlos
						const patenteProcessed = {
							...data,
							codigo: data.codigo ? JSON.parse(data.codigo.replace(/'/g, '"')) : [],
							fechaEdicion: data.fechaEdicion ? JSON.parse(data.fechaEdicion.replace(/'/g, '"')) : [],
							numeroExpediente: data.numeroExpediente ? JSON.parse(data.numeroExpediente.replace(/'/g, '"')) : []
						};

						setStore({
							patente: patenteProcessed,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: "patente " + error.message,
						});
					});
			},

			crearPatente: async (datosPatente) => {
				const store = getStore();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/patentes", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
					body: JSON.stringify(datosPatente),
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			editarPatente: async (id, datosPatente, onVista, busqueda=null) => {
				const store = getStore();
				const actions = getActions();
				setStore({ spinner: true });
				
				try {
					// Primero hacemos el PUT a la BD principal
					const response = await fetch("https://denegocios.herokuapp.com/patentes/" + id, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + store.token,
						},
						body: JSON.stringify(datosPatente),
					});
					
					const data = await response.json();
					setStore({ response: data });
			
					// Si el estado es "Terminado" hacemos el POST a Zapier
					if (datosPatente.estado === "Terminada") {
						await fetch("https://hooks.zapier.com/hooks/catch/2499772/2s6pjc9/", {
							method: "POST",
							body: JSON.stringify({
								rut: datosPatente.rut,
								razonSocial: datosPatente.razonSocial,
								correo: datosPatente.correo,
							}),
						});
					}
			
					// Actualizamos la vista según corresponda
					if (onVista === "Todas" || onVista ==="EnTramite" || onVista === "Muertas" || onVista === "Terminadas") {
						actions.getPatente(id);
						actions.getPatentes(onVista);
					} else if (onVista === "Busqueda") {
						actions.getPatente(id);
						actions.getBusquedaIndividualPatente(busqueda);
					}
				} catch (error) {
					setStore({ error: error.message });
				} finally {
					setStore({ spinner: false });
				}
			},

			borrarPatente: async (id) => {
				const store = getStore();
				const actions = getActions();
				setStore({ spinner: true });
				fetch("https://denegocios.herokuapp.com/patentes/" + id, {
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + store.token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						setStore({
							response: data,
						});
						actions.getPatentes();
						setStore({ spinner: false });
					})
					.catch((error) => {
						setStore({
							error: error.message,
						});
					});
			},

			getBusquedaIndividualPatente: async (busqueda) => {
				const store = getStore();
				setStore({ spinner: true });
				
				try {
					const response = await fetch("https://denegocios.herokuapp.com/busquedaPatentes", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + store.token,
						},
						body: JSON.stringify({
							busqueda: busqueda
						}),
					});

					if (!response.ok) {
						if (response.status === 404) {
							setStore({
								patentesEncontradas: [],
								error: "No se encontraron resultados para la búsqueda"
							});
							return false;
						}
						throw new Error("Error en la búsqueda");
					}

					const data = await response.json();
					// Convertir los datos antes de almacenarlos
					const patentesProcessed = data.map(patente => ({
						...patente,
						codigo: patente.codigo ? JSON.parse(patente.codigo.replace(/'/g, '"')) : [],
						fechaEdicion: patente.fechaEdicion ? JSON.parse(patente.fechaEdicion.replace(/'/g, '"')) : [],
						numeroExpediente: patente.numeroExpediente ? JSON.parse(patente.numeroExpediente.replace(/'/g, '"')) : []
					}));

					setStore({
						patentesEncontradas: patentesProcessed,
						error: null
					});
					return true;

				} catch (error) {
					setStore({
						error: "Error en la búsqueda: " + error.message,
						patentesEncontradas: []
					});
					return false;
				} finally {
					setStore({ spinner: false });
				}
			},
		},
	};
};

export default getState;
