import React, { useContext } from 'react';
import { Context } from "../../store/AppContext";

const RenderPatenteDatos = () => {
  const { store } = useContext(Context);
  const { patente } = store;

  if (!patente) return null;

  const entries = Object.entries(patente).filter(([key]) => 
    !['id', 'usuarioCreador', 'terminoComentarios'].includes(key)
  );
  const mitad = Math.ceil(entries.length / 2);

  const camposVisualizacion = {
    codigo: "Código",
    numeroExpediente: "Número de Expediente",
    rut: "Rut",
    razonSocial: "Razón Social",
    correo: "Correo",
    estado: "Estado",
    revisionEstado: "Revisión de Estado",
    fechaCreacion: "Fecha de Creación",
    fechaEdicion: "Fecha de Última Edición"
  };

  const camposConHistorial = ['codigo', 'numeroExpediente', 'fechaEdicion'];

  const renderSelectTuplas = (tuplas, key) => {
    if (!Array.isArray(tuplas) || tuplas.length === 0) return '';

    // Invertir el orden del array para mostrar las últimas tuplas primero
    const reversedTuplas = [...tuplas].reverse();
    
    const formatOption = (tupla) => {
      const [valor, fecha] = tupla;
      return key === 'fechaEdicion' 
        ? `${fecha} (${valor})`
        : `${valor} (${fecha})`;
    };

    return (
      <select 
        className="select-tuplas"
        defaultValue={formatOption(reversedTuplas[0])}
        style={{width: '100%'}}
      >
        {reversedTuplas.map((tupla, index) => (
          <option key={index} value={formatOption(tupla)}>
            {formatOption(tupla)}
          </option>
        ))}
      </select>
    );
  };

  return (
    <div>
      <div className="grid-x grid-padding-x">
          <div className="cell medium-6">
            {entries.slice(0, mitad).map(([key, value]) => (
              <div key={key} style={{marginBottom: '1rem'}}>
                <strong>{camposVisualizacion[key] || key}:</strong>{' '}
                {camposConHistorial.includes(key) ? (
                  renderSelectTuplas(value, key)
                ) : (
                  <span>{value}</span>
                )}
              </div>
            ))}
          </div>
          <div className="cell medium-6">
            {entries.slice(mitad).map(([key, value]) => (
              <div key={key} style={{marginBottom: '1rem'}}>
                <strong>{camposVisualizacion[key] || key}:</strong>{' '}
                {camposConHistorial.includes(key) ? (
                  renderSelectTuplas(value, key)
                ) : (
                  <span>{value}</span>
                )}
              </div>
            ))}
          </div>
      </div>
      <div className="grid-x grid-padding-x">
        <div className="cell">
          <h4>Término Comentarios</h4>
          <div style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '10px',
            minHeight: '6rem',
            whiteSpace: 'pre-wrap'
          }}>
            {patente.terminoComentarios}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderPatenteDatos;