import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../store/AppContext";
import PaginadorContabilidad from "../../PaginadorContabilidad.js";
import ListaClientesContaCompleta from "./ListaClientesContaCompleta";
import ClienteSeleccionado from "../../ClienteSeleccionado.js";
import { ExportTableToExcel } from "../../../../Helper/ExportTableToExcel";

/* Busqueda con BD Completa */

const BusquedaCompleta = ({ setBusquedaCompleta, tipoFiltro }) => {
	const { actions } = useContext(Context);
	const [clienteSeleccionado, setClienteSeleccionado] = useState(false);
	const [clienteContaCliqueado, setClienteContaCliqueado] = useState(null);

	useEffect(() => {
		if (tipoFiltro === "Todo") {
			actions.getClientesContabilidad(1);
		} else if (tipoFiltro === "Vigente") {
			actions.getBusquedaFiltroContabilidad("Si", "Selecciona...", "Selecciona...", "Selecciona...", "Selecciona...");
		} else if (tipoFiltro === "Con DICOM") {
			actions.getBusquedaFiltroContabilidad("Selecciona...", "Selecciona...", "Selecciona...", "Si", "Selecciona...");
		} else if (tipoFiltro === "Con Riesgo") {
			actions.getBusquedaFiltroContabilidad("Selecciona...", "Selecciona...", "Selecciona...", "Selecciona...", "Si");
		} else if (tipoFiltro === "No Vigente") {
			actions.getBusquedaFiltroContabilidad("No", "Selecciona...", "Selecciona...", "Selecciona...", "Selecciona...");
		} else {
			actions.getBusquedaContabilidad("", tipoFiltro);
		}		
	}, []);

	const HandlerExportarTabla = (event) => {
		ExportTableToExcel("xlsx");
	};

	const tituloListado = () => {
		return (
			<>
				<h2>Lista de Clientes Contabilidad {tipoFiltro.toString()}s</h2>
				<small>
					*Lista actualizada al {new Date().toLocaleString()}
				</small>
			</>
		);
	};

	return (
		<div>
			<div>
				<div className='no-print row button-group align-right'>
					<button
						className='clear button alert'
						onClick={(e) => setBusquedaCompleta(false)}
					>
						Regresar Panel Inicial
					</button>
					{clienteSeleccionado && (
						<>
							<a class='clear button' href='#' disabled>
								&#62;
							</a>

							<button
								className='clear button alert'
								onClick={(e) => setClienteSeleccionado(false)}
							>
								Regresar Listado Previo
							</button>
						</>
					)}
				</div>
				<>
					{/* Botones de Opciones */}
					<div className='row'>
						{clienteSeleccionado && (
							<ClienteSeleccionado
								setClienteSeleccionado={setClienteSeleccionado}
								clienteContaCliqueado={clienteContaCliqueado}
							/>
						)}
						{!clienteSeleccionado && (
							<>
								{tituloListado()}
								<div className='columns small-12'>
									<div className='columns small-10'>&nbsp;</div>
									<div className='columns small-2'>
										<button
											className='submit button expanded'
											onClick={(e) => HandlerExportarTabla(e)}
										>
											Exportar Seleccion
										</button>
									</div>
								</div>
							</>
						)}
					</div>
					<br />
					{!clienteSeleccionado && (
						<>
							<ListaClientesContaCompleta
								setClienteSeleccionado={setClienteSeleccionado}
								setClienteContaCliqueado={setClienteContaCliqueado}
								tipoFiltro={tipoFiltro}
							/>
							<div className='' style={{ textAlign: "center" }}>
								<br />
								<PaginadorContabilidad />
							</div>
						</>
					)}
				</>
			</div>
		</div>
	);
};

export default BusquedaCompleta;
