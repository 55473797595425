import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../../store/AppContext';
import { formatRut, validateRut } from '../../Helper/RutUtils';

const AgregarNuevoClientePatentes = ({ isOpen, onClose }) => {
  const { store, actions } = useContext(Context);
  const initialFormData = {
    codigo: '',
    numeroExpediente: '',
    rut: '',
    razonSocial: '',
    correo: '',
    estado: 'Pendiente',
    revisionEstado: new Date().toISOString().split('T')[0],
    terminoComentarios: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [rutError, setRutError] = useState('');
  const [formErrors, setFormErrors] = useState({
    codigo: '',
    numeroExpediente: '',
    correo: '',
    terminoComentarios: ''
  });

  useEffect(() => {
    if (isOpen) {
      const fechaActual = new Date().toISOString().split('T')[0];
      setFormData({
        ...initialFormData,
        revisionEstado: fechaActual
      });
    } else {
      setFormData(initialFormData);
    }
    setRutError('');
  }, [isOpen]);

  const handleChange = (e) => {
    if (e.target.name === 'rut') {
      const formattedRut = formatRut(e.target.value);
      setFormData({ ...formData, rut: formattedRut });
      setRutError('');
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      codigo: '',
      numeroExpediente: '',
      correo: '',
      terminoComentarios: ''
    };

    // Validar código
    if (formData.codigo && !/^\d+$/.test(formData.codigo)) {
      newErrors.codigo = 'El código debe contener solo números';
      isValid = false;
    }

    // Validar número de expediente
    if (formData.numeroExpediente && !/^\d+$/.test(formData.numeroExpediente)) {
      newErrors.numeroExpediente = 'El número de expediente debe contener solo números';
      isValid = false;
    }

    // Validar correo
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.correo)) {
      newErrors.correo = 'Por favor, ingrese un correo electrónico válido';
      isValid = false;
    }

    // Validar término/comentarios
    if (formData.terminoComentarios.length > 100) {
      newErrors.terminoComentarios = 'El comentario no puede exceder los 100 caracteres';
      isValid = false;
    }

    setFormErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!validateRut(formData.rut)) {
      setRutError('Por favor, ingrese un RUT válido con el formato xx.xxx.xxx-x');
      return;
    }

    if (!validateForm()) {
      return;
    }

    const fechaActual = new Date().toLocaleString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).split('/').reverse().join('-');

    const usuarioCreador = `${store.usuarioActual.nombre} ${store.usuarioActual.apellido}`;

    const nuevaPatente = {
      ...formData,
      codigo: formData.codigo ? [[parseInt(formData.codigo), fechaActual]] : [],
      numeroExpediente: formData.numeroExpediente ? [[parseInt(formData.numeroExpediente), fechaActual]] : [],
      fechaCreacion: fechaActual,
      fechaEdicion: [[fechaActual, usuarioCreador]],
      usuarioCreador: usuarioCreador
    };

    actions.crearPatente(nuevaPatente);
    setFormData(initialFormData); // Reiniciar el formulario
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="reveal-overlay" style={{display: 'block'}}>
      <div className="reveal" style={{display: 'block'}}>
        <h2>Agregar Nueva Patente</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid-x grid-padding-x">
            <div className="cell small-6">
              <label>
                Código
                <input type="text" name="codigo" value={formData.codigo} onChange={handleChange} />
              </label>
              {formErrors.codigo && <small style={{color: 'red', display: 'block'}}>{formErrors.codigo}</small>}
            </div>
            <div className="cell small-6">
              <label>
                Número de Expediente
                <input type="text" name="numeroExpediente" value={formData.numeroExpediente} onChange={handleChange} />
              </label>
              {formErrors.numeroExpediente && <small style={{color: 'red', display: 'block'}}>{formErrors.numeroExpediente}</small>}
            </div>
            <div className="cell small-6">
              <label>
                RUT *
                <input 
                  type="text" 
                  name="rut" 
                  value={formData.rut} 
                  onChange={handleChange}
                  onBlur={() => {
                    if (!validateRut(formData.rut)) {
                      setRutError('Formato inválido, revise su RUT.');
                    }
                  }}
                  maxLength={12}
                  placeholder="Ej: 17.011.079-K"
                  required 
                />
              </label>
              {rutError && <small style={{color: 'red', display: 'block'}}>{rutError}</small>}
            </div>
            <div className="cell small-6">
              <label>
                Razón Social *
                <input type="text" name="razonSocial" value={formData.razonSocial} onChange={handleChange} required />
              </label>
            </div>
            <div className="cell small-6">
              <label>
                Correo Electrónico *
                <input type="email" name="correo" value={formData.correo} onChange={handleChange} required />
              </label>
              {formErrors.correo && <small style={{color: 'red', display: 'block'}}>{formErrors.correo}</small>}
            </div>
            <div className="cell small-6">
              <label>
                Estado
                <select name="estado" value={formData.estado} onChange={handleChange}>
                  <option value="En Proceso">En Proceso</option>
                  <option value="Terminada">Terminada</option>
                  <option value="Rechazada">Rechazada</option>
                  <option value="Pendiente">Pendiente</option>
                  <option value="Devolucion">Devolucion</option>
                  <option value="Cliente No Responde">Cliente No Responde</option>
                  <option value="No Sigue Proceso">No Sigue Proceso</option>
                  <option value="Ultima Etapa">Ultima Etapa</option>
                  <option value="En Revision">En Revision</option>
                </select>
              </label>
            </div>
            <div className="cell small-6">
              <label>
                Fecha de Revisión de Estado
                <input 
                  type="date" 
                  name="revisionEstado" 
                  value={formData.revisionEstado} 
                  onChange={handleChange} 
                />
              </label>
            </div>
            <div className="cell">
              <label>
                Término / Comentarios
                <textarea 
                  name="terminoComentarios" 
                  value={formData.terminoComentarios} 
                  onChange={handleChange} 
                  rows="3"
                ></textarea>
                <small>{formData.terminoComentarios.length}/100 caracteres</small>
              </label>
              {formErrors.terminoComentarios && <small style={{color: 'red', display: 'block'}}>{formErrors.terminoComentarios}</small>}
            </div>
          </div>
          <div className="grid-x grid-padding-x">
            <div className="cell small-6">
              <button type="submit" className="button expanded">Agregar Patente</button>
            </div>
            <div className="cell small-6">
              <button type="button" className="button secondary expanded" onClick={onClose}>Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AgregarNuevoClientePatentes;
