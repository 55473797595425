import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../store/AppContext';
import RenderPatenteTabla from './RenderPatenteTabla';
import ModalPatenteSeleccionada from './ModalPatenteSeleccionada';

const PatentesTerminadas = ({ onRegresar }) => {
  const { store, actions } = useContext(Context);
  const [modalPatente, setModalPatente] = useState(null);
  const onVista = "Terminadas";

  useEffect(() => {
    actions.getPatentes(onVista);
  }, []);

  const abrirModal = (patente) => {
    actions.getPatente(patente.id);
    setTimeout(() => {
      setModalPatente(store.patente);
    }, 700);
  };

  const actualizarModal = (patenteId) => {
    actions.getPatente(patenteId);
    setTimeout(() => {
      setModalPatente(store.patente);
    }, 700);
  };

  const cerrarModal = () => {
    setModalPatente(null);
  };

  const patentesTerminadas = store.patentes ? store.patentes.filter(patente => patente.estado === "Terminada") : [];

  return (
    <div className="grid-container">
      <div className="grid-x grid-padding-x">
        <div className="cell">
          <button className="button" onClick={onRegresar}>Regresar</button>
          <h2>Patentes Terminadas</h2>
          <RenderPatenteTabla patentes={patentesTerminadas} onClickFila={abrirModal}/>
          <ModalPatenteSeleccionada 
            patente={modalPatente} 
            onClose={cerrarModal}
            onUpdate={actualizarModal}
            onVista={onVista}
          />
        </div>
      </div>
    </div>
  );
};

export default PatentesTerminadas;
