import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Context } from '../store/AppContext';
import Head from '../component/Head';
import AgregarNuevoClientePatentes from '../component/Patentes/AgregarNuevoClientePatentes';
import PatentesTerminadas from '../component/Patentes/PatentesTerminadas';
import PatentesEnTramite from '../component/Patentes/PatentesEnTramite';
import PatentesTodas from '../component/Patentes/PatentesTodas';
import BusquedaIndividual from '../component/Patentes/BusquedaIndividual';
import PatentesMuertas from '../component/Patentes/PatentesMuertas';
const Patentes = () => {
    const { store } = useContext(Context);
    const [animar, setAnimar] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [vistaActual, setVistaActual] = useState('principal');
    const titulosHead = [
		"Bienvenido al Servicio de Patentes Municipales",
		"Consulta información respecto a Clientes con este servicio contratado.",
	];

    const tipoUsuario = store.usuarioActual?.tipo;
    const tienePermisos = ['Administrador', 'Super Administrador', 'Soporte'].includes(tipoUsuario);

    //Animacion
	useEffect(() => {
		setAnimar(true);
	}, []);

    const openModal = () => tienePermisos && setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const mostrarTerminadas = () => tienePermisos && setVistaActual('terminadas');
    const mostrarEnTramite = () => tienePermisos && setVistaActual('enTramite');
    const regresarAPatentes = () => setVistaActual('principal');
    const mostrarTodasLasPatentes = () => tienePermisos && setVistaActual('todas');
    const mostrarBusquedaIndividual = () => setVistaActual('busqueda');
    const mostrarMuertas = () => tienePermisos && setVistaActual('muertas');

	return (
		<Fragment>
			<Head contenido={titulosHead} />
            {vistaActual === 'terminadas' ? (
                <PatentesTerminadas onRegresar={regresarAPatentes} />
            ) : (null)}
            {vistaActual === 'enTramite' ? (
                <PatentesEnTramite onRegresar={regresarAPatentes} />
            ) : (null)}
            {vistaActual === 'todas' ? (
                <PatentesTodas onRegresar={regresarAPatentes} />
            ) : (null)}
            {vistaActual === 'busqueda' ? (
                <BusquedaIndividual onRegresar={regresarAPatentes} />
            ) : (null)}
            {vistaActual === 'muertas' ? (
                <PatentesMuertas onRegresar={regresarAPatentes} />
            ) : (null)}
            {vistaActual === 'principal' ? (
			<div className='row'>
				<div className='column'>
					<div className={`row divGenerador ${animar && "animar"}`}>
                    <div className='columns small-12 callout'>
                        <div className='columns small-4'>Puedes:</div>
                        <div className='columns small-8'>Ver Listado de Clientes:</div>
                        &nbsp;
                        <div className='columns small-12'>
                            <div className='columns small-4'>
                                <a
                                    className={`success button large ${!tienePermisos ? 'disabled' : ''}`}
                                    id='NuevoCliente'
                                    onClick={openModal}
                                >
                                    Agregar Cliente Nuevo
                                </a>
                            </div>
                            <div className='columns small-2'>
                                <a
                                    className={`button large ${!tienePermisos ? 'disabled' : ''}`}
                                    id='EnTramite'
                                    onClick={mostrarEnTramite}
                                >
                                    Patentes en Trámite
                                </a>
                            </div>
                            <div className='columns small-2'>
                                <a
                                    className={`button large ${!tienePermisos ? 'disabled' : ''}`}
                                    id='terminadas'
                                    onClick={mostrarTerminadas}
                                >
                                    Patentes Terminadas
                                </a>
                            </div>
                            <div className='columns small-2'>
                                <a
                                    className={`button large ${!tienePermisos ? 'disabled' : ''}`}
                                    id='Muertas'
                                    onClick={mostrarMuertas}
                                >
                                    Patentes Muertas
                                </a>
                            </div>
                            <div className='columns small-2'>
                                <a
                                    className={`alert button large ${!tienePermisos ? 'disabled' : ''}`}
                                    id='Todas'
                                    onClick={mostrarTodasLasPatentes}
                                >
                                    Ver todas las Patentes
                                </a>
                            </div>
                        </div>
                    </div>
                        {/* Busqueda Clientes */}
                        <div className='columns small-12 callout'>
                            <div className='columns small-12'>También Puedes:</div>
                            &nbsp;
                            <div className='columns small-12'>
                                <a
                                    className='warning button large expanded'
                                    onClick={mostrarBusquedaIndividual}
                                >
                                    Realizar Busqueda de Clientes con Patente
                                </a>
                            </div>
                        </div>
					</div>
				</div>
			</div>
            ):(null)}
            <AgregarNuevoClientePatentes isOpen={isModalOpen} onClose={closeModal} />
		</Fragment>
	);
};

export default Patentes;
